import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';

import { environment } from '../environments/environment';

/* Material */
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/* XPO */
import { ModuleNotificationsModule } from '@xpo-ltl/module-executor-ui';
import {
  XpoAccountPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoUploadModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';

import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XpoMessagingPopoverModule } from '@xpo-ltl/ngx-ltl-core';

/* SDK */
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';

/* Modules */
import { AppRoutingModule } from './app-routing.module';
import { DialogModule } from './dialogs/dialog.module';
import { SharedModule } from './shared/shared.module';

/* Components */
import { NgIdleModule } from '@ng-idle/core';
import { NotificationComponent } from './app-notification.component';
import { AppComponent } from './app.component';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    ModuleNotificationsModule,
    XpoAccountPopoverModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoMessagingPopoverModule,
    XpoSnackBarModule,
    XpoUploadModule,
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.COST_ALLC }),
    SdkAppMetadataModule,
    SdkLocationModule,
    NgIdleModule.forRoot(),
    // For SSO
    XpoLtlAuthUiModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    LocationApiService,
    NotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
