import { Component, ViewEncapsulation } from '@angular/core';
import { CostAllocationService } from '@app/extract-upload-workbench/services/cost-allocation.service';

/* XPO */
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoAccountPopoverConfig, XpoMessagingPopoverMessage, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { CostAllocationApiService } from '@xpo-ltl/sdk-costallocation';

/* Rxjs */
import { interval, Observable, of } from 'rxjs';
import { delay, retryWhen, skipWhile, take } from 'rxjs/operators';

/* Enums */
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

/* Services */
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader/dynamic-script-loader.service';
import { IdleService } from './shared/services/idle/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CostAllocationApiService],
})
export class AppComponent {
  title = 'Cost Modeling';

  build: string;
  region: string;

  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  messageData: Observable<XpoMessagingPopoverMessage[]>;
  isLoggedInUser = false;

  get accountUrls() {
    return AccountUrls;
  }

  constructor(
    private configManagerService: ConfigManagerService,
    private costAllocationService: CostAllocationApiService,
    private authService: XpoLtlAuthenticationService,
    private idleService: IdleService,
    private dynamicScriptLoader: DynamicScriptLoaderService
  ) {
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.region = `${configManagerService.getSetting<string>(ConfigManagerProperties.region).toLowerCase()}`;

    this.authService.initAuthSetup$(this.region).subscribe((info: RegionInfo) => {
      this.loggedInUserFunc();
    });

    this.idleService.init();

    this.routes = [
      {
        label: 'Cost Allocation',
        path: null,
        children: [
          {
            label: 'Module Library',
            path: `/${AppRoutes.MODULE_LIBRARY_WORKBENCH_PAGE}`,
          },
          {
            label: 'Module Groups',
            path: `/${AppRoutes.MODULE_GROUPS_WORKBENCH_PAGE}`,
          },
          {
            label: 'DAG Inputs',
            path: `/${AppRoutes.EXTRACT_UPLOAD_PAGE}`,
          },
        ],
      },
      {
        label: 'Cost Estimation',
        path: null,
        children: [
          {
            label: 'Submission',
            path: `/${AppRoutes.SUBMISSION}`,
          },
          {
            label: 'Job Executions',
            path: `/${AppRoutes.JOB_EXECUTIONS}`,
          },
          {
            label: 'Results',
            path: `/${AppRoutes.COST_ESTIMATION_RESULTS}`,
          },
        ],
      },
    ];
  }

  private loggedInUserFunc(): void {
    this.costAllocationService
      .loggedInUser()
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            this.setDynatraceUserIdentity(user);
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private setDynatraceUserIdentity(user: User): void {
    // Based on region we need to load the proper dynatrace script and once it is
    // loaded attempt to set the current user through dtrum variable.
    const region = `${this.configManagerService.getSetting<string>(ConfigManagerProperties.region).toLowerCase()}`;
    this.dynamicScriptLoader.load({ name: 'dynatrace', src: `./assets/dyna_${region.toLowerCase()}.js` }).subscribe(() => {
      const setIdentity = (u: User) => {
        const identifyUser = window['dtrum'] && window['dtrum'].identifyUser;
        if (typeof identifyUser === 'function') {
          const userId = u.emailAddress && u.emailAddress.trim() !== '' ? u.emailAddress : u.userId;
          identifyUser(userId);
        }
      };

      if (window['dtrum']) {
        setIdentity(user);
      } else {
        interval(100)
          .pipe(
            skipWhile(() => !window['dtrum']),
            take(1)
          )
          .subscribe(() => {
            this.isLoggedInUser = true;
            setIdentity(user);
          });
      }
    });
  }
}
