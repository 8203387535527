<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<div mat-dialog-content class="ltlc-ConfirmationDialog-dialogContent">
  <span [innerHTML]="data.body" class="ltlc-ConfirmationDialog-dialogContent-text"></span>
</div>

<div mat-dialog-actions>
  <button mat-flat-button [attr.data-cy]="'dialogButton-confirm'" [disabled]="isLoading" (click)="confirm()">
    <div class="ltlc-ConfirmationDialog-buttonContent">
      {{ data.actionText }}
      <div class="ltlc-ConfirmationDialog-spinnerWrapepr" *ngIf="isLoading">
        <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
      </div>
    </div>
  </button>
  <button mat-button mat-dialog-close color="primary" dataCy="dialogButton-cancel">
    {{ data?.cancelText || 'Cancel' }}
  </button>
</div>
