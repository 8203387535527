import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: '',
    redirectTo: AppRoutes.MODULE_GROUPS_WORKBENCH_PAGE,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.MODULE_LIBRARY_WORKBENCH_PAGE,
    loadChildren: () =>
      import('./module-library-workbench/module-library-workbench.module').then((mod) => mod.ModuleLibraryWorkbenchModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.MODULE_GROUPS_WORKBENCH_PAGE,
    loadChildren: () => import('./module-groups-workbench/module-groups-workbench.module').then((mod) => mod.ModuleGroupsWorkbenchModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.MODULE_GROUP_MANAGEMENT_PAGE,
    loadChildren: () =>
      import('./module-group-management/module-group-management.module').then((mod) => mod.ModuleGroupManagementWorkbenchModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.EXTRACT_UPLOAD_PAGE,
    loadChildren: () =>
      import('./extract-upload-workbench/extract-upload-workbench.module').then((mod) => mod.ExtractUploadWorkbenchModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.RESULT_REPORTS_PAGE,
    loadChildren: () => import('./module-result-reports/module-result-reports.module').then((mod) => mod.ModuleResultReportsModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: '**',
    redirectTo: AppRoutes.MODULE_GROUPS_WORKBENCH_PAGE,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
