import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { XpoButtonModule, XpoDialogModule, XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
  ConfirmationComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoButtonModule,
    XpoDialogModule,
    XpoIconModule,
    MatProgressSpinnerModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
