import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { ConfirmationDialogData } from './confirmation.interface';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private cd: ChangeDetectorRef,
    private snackbar: XpoSnackBar
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      this.dialogRef.close(false);
      console.error('no data defined!');
    }
  }

  confirm(): void {
    if (this.data.actionText === 'Close') {
      this.dialogRef.close(false);
      return;
    }
    if (this.data.handleConfirmation && this.data.actionText !== 'Close') {
      this.isLoading = true;
      this.data
        .handleConfirmation()
        .pipe(
          take(1),
          catchError((error) => {
            this.dialogRef.close(false);
            this.openErrorSnackBar(error);
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
            this.cd.markForCheck();
          })
        )
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    } else {
      this.dialogRef.close(true);
    }
  }

  openErrorSnackBar(error: any): void {
    this.snackbar.error(error);
  }
}
