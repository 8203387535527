export enum AppRoutes {
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  MODULE_LIBRARY_WORKBENCH_PAGE = 'module-library-workbench',
  MODULE_GROUPS_WORKBENCH_PAGE = 'module-groups-workbench',
  MODULE_GROUP_MANAGEMENT_PAGE = 'module-group-management',
  EXTRACT_UPLOAD_PAGE = 'dag-inputs',
  RESULT_REPORTS_PAGE = 'result-reports',
  SUBMISSION = 'submission',
  JOB_EXECUTIONS = 'job-executions',
  COST_ESTIMATION_RESULTS = 'cost-est-results',
}
