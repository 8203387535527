import { NgModule } from '@angular/core';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    // Pipes/Directives
  ],
  imports: [
    // Pipes/Directives
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    // Pipes/Directives
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
