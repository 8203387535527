import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '@app/dialogs/confirmation/confirmation.component';
import { ConfirmationDialogData } from '@app/dialogs/confirmation/confirmation.interface';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  // 25 mins to idle
  private readonly idleTimeInSeconds = 1500;
  private confirmationDialogIsOpen = false;

  constructor(
    private idle: Idle,
    private ltlAuthenticationService: XpoLtlAuthenticationService,
    private authenticationService: XpoAuthenticationService,
    private dialog: MatDialog
  ) {}

  init(): void {
    this.idle.setIdle(this.idleTimeInSeconds);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => {
      if (!this.confirmationDialogIsOpen) {
        this.confirmationDialogIsOpen = true;

        // Clear the token so fresh one can be fetched if user hits cancel instead of refresh.
        this.ltlAuthenticationService.logout();

        this.open({
          title: 'Session Expired',
          body: 'Your session has expired due to inactivity.',
          actionText: 'Continue Session',
          actionColor: 'primary',
          hideCancelButton: false,
          cancelText: 'End Session',
          disableClose: true,
        })
          .pipe(take(1))
          .subscribe((confirm) => {
            this.confirmationDialogIsOpen = false;

            if (confirm) {
              window.location.href = window.location.href;
            } else {
              this.authenticationService
                .signOut$()
                .pipe(
                  take(1),
                  tap(() => sessionStorage.clear())
                )
                .subscribe();
            }
          });
      }
    });
    this.idle.watch();
  }

  open(data: ConfirmationDialogData): Observable<boolean> {
    return this.dialog.open(ConfirmationComponent, { data, width: '600px', disableClose: data.disableClose }).afterClosed();
  }
}
