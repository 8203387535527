<xpo-shell class="app-Container" [routes]="routes" [drawerClosedByDefault]="false" [versionNumber]="build">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-actions>
    <ltl-xpo-module-notifications *ngIf="isLoggedInUser"> </ltl-xpo-module-notifications>
    <xpo-auth-user-profile>
      <xpo-auth-user-profile-links>
        <a [href]="accountUrls.myAccount" target="_blank">My Account</a>
        <a [href]="accountUrls.help" target="_blank">Help</a>
        <a [href]="accountUrls.privacyPolicy" target="_blank">Privacy Policy</a>
      </xpo-auth-user-profile-links>
      <xpo-auth-user-profile-actions> </xpo-auth-user-profile-actions>
    </xpo-auth-user-profile>
  </xpo-header-actions>
  <router-outlet></router-outlet>
</xpo-shell>
<app-notification></app-notification>
